import React, { useState } from 'react';
import { logoutUser, loginUser } from '../services/api'; // api.js에서 함수 불러오기
import '../styles/Navbar.css';

const Navbar = ({ isLoggedIn, setIsLoggedIn, setIsAdmin, setIsManeger }) => { // setIsLoggedIn prop 추가
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    loginUser({ userName, password })
      .then((response) => { // 'response'를 사용하여 서버 응답을 처리
        alert('로그인에 성공했습니다.');
        console.log('로그인 성공:', response);
        setIsLoggedIn(true); // 로그인 시 상태 업데이트
        if(response.isAdmin){
          setIsAdmin(true); // 관리자 여부 상태 업데이트
        }
        if(response.isManager){
          setIsManeger(true); // 매니저 여부 상태 업데이트
        }
        window.location.href = '/'; // 로그아웃 후 홈페이지로 이동
      })
      .catch(error => {
        console.error('로그인 실패:', error);
        alert('로그인에 실패했습니다.');
        window.location.href = '/'; // 로그아웃 후 홈페이지로 이동
      });
  };

  const handleLogout = () => {
    logoutUser()
      .then(() => {
        alert('로그아웃 되었습니다.');
        window.location.href = '/'; // 로그아웃 후 홈페이지로 이동
      })
      .catch(error => {
        console.error('로그아웃 실패:', error);
        window.location.href = '/'; // 로그아웃 후 홈페이지로 이동
      });
  };

  return (
    <nav className="navbar">
      <h1>Edu Archive</h1>
      <div className="auth-container">
        {isLoggedIn ? (
          <>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <div className="login-form">
            <input
              type="text"
              placeholder="Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;