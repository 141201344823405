import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import FileTree from './components/FileTree';
import Navbar from './components/Navbar';
import ViewUser from './components/ViewUser'; // ViewUser 컴포넌트 불러오기
import { checkSession, getDirectories } from './services/api'; // api.js에서 함수 불러오기
import './styles/App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // 관리자 여부 상태 추가
  const [directories, setDirectories] = useState([]); // 디렉토리 상태 추가

  // 5초마다 세션 상태를 체크하는 useEffect
  useEffect(() => {
    if (isLoggedIn) {
      const checkSessionInterval = setInterval(() => {
        // 로그인된 상태일 때만 세션 상태 확인
        checkSession()
          .then(response => {
            if (!response.isLoggedIn) {
              alert("세션이 만료되었습니다.");
              window.location.href = "/"; // 세션 만료 시 리디렉션
            }
            setIsLoggedIn(response.isLoggedIn);
            setIsManager(response.isManager);
            setIsAdmin(response.isAdmin);
          })
          .catch(() => {
            alert("세션 확인 중 오류가 발생했습니다.");
            setIsLoggedIn(false); // 오류 발생 시 로그아웃 처리
            window.location.href = "/"; // 리디렉션
          });
      }, 6*60000); // 6분마다 호출 -> 세션 만료시간이 5분이기 때문

      // 컴포넌트 언마운트 시 setInterval 정리
      return () => clearInterval(checkSessionInterval);
    }
  }, [isLoggedIn]); // isLoggedIn이 변경될 때만 호출

  // 첫 로그인 시 세션 확인
  useEffect(() => {
    // 세션 확인 API를 통해 로그인 여부를 확인
    checkSession()
      .then(response => {
        setIsLoggedIn(response.isLoggedIn);
        setIsManager(response.isManager);
        setIsAdmin(response.isAdmin);
      })
      .catch(() => {
        setIsLoggedIn(false); // 세션 체크 실패 시 로그아웃 처리
      });
  }, []); // 최초 1회만 실행

  useEffect(() => {
    // 디렉토리 API 요청
    const fetchDirectories = async () => {
      try {
        const response = await getDirectories();
        setDirectories(response); // 디렉토리 상태 업데이트
      } catch (error) {
        console.error('Error fetching directories:', error);
      }
    };

    fetchDirectories(); // 컴포넌트가 마운트될 때 디렉토리 가져오기
  }, []); // 빈 배열을 의존성으로 두어 컴포넌트가 마운트될 때만 호출되게 설정

  return (
    <div className="App">
      <Router>
        <Navbar
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          isManager={isManager}
          setIsManager={setIsManager}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
        />
        <div className="container">
          <div className="content">
            <FileTree
              directories={directories}
              isLoggedIn={isLoggedIn}
              isManager={isManager}
              isAdmin={isAdmin}
            />
          </div>
          {isAdmin && (
            <div className="content">
              <ViewUser />
            </div>
          )}
        </div>
      </Router>
    </div>
  );
}

export default App;